.thank-you-page {
  .thank-you-block {
    margin-top: rem(80px);

    @include mq($until: iphone-11) {
      margin-top: rem(56px);
    }

    .thank-you__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 rem(20px);
      text-align: center;

      &.thank-you-trial {
        justify-content: center;
        padding: 0 rem(20px);
        flex-direction: row;
        text-align: left;

        @include mq($until: wide) {
          flex-direction: column;
        }

        h5 {
          max-width: rem(488px);
        }

        .thank-you-wrapper__bg {
          .thank-you__icons {
            top: rem(62px);
          }
        }
      }

      .thank-you-wrapper__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: rem(60px);

        @include mq($until: wide) {
          text-align: center;
        }

        h5 {
          margin-top: rem(16px);
        }
      }

      .thank-you-title {
        margin-top: rem(30px);

        h1 {
          font-size: rem(98px);
          margin-bottom: rem(20px);
        }
      }

      h1 {
        max-width: rem(750px);
        width: 100%;
        color: $font-4;
        font-size: rem(72px);
        line-height: rem(80px);
        text-transform: capitalize;
        margin: 0;

        span {
          color: $main-500;
        }

        @include mq($until: iphone-11) {
          font-size: rem(40px);
          line-height: rem(44px);
          align-self: flex-start;
        }
      }

      h5 {
        max-width: rem(660px);
        margin-top: rem(45px);
        margin-bottom: 0;
        font-size: rem(24px);
        line-height: rem(32px);
        font-weight: 500;
        color: $font-3;

        @include mq($until: iphone-11) {
          margin-top: rem(12px);
          font-size: rem(16px);
          line-height: rem(20px);
          align-self: flex-start;
        }

        .thank-you-download-sample {
          @keyframes fade-in-left {
            0% {
              opacity: 0;
              transform: translateX(-100px);
            }
            100% {
              opacity: 1;
              transform: translateX(0);
            }
          }

          animation: fade-in-left 0.5s forwards;

          margin-top: rem(25px);
          padding: rem(16px);
          display: flex;
          align-items: center;
          background: $grey-000;
          box-shadow: 0 -2.598px 20px 1px rgba(94, 102, 102, 0.2);

          span {
            display: block;
            width: 100%;
            font-size: rem(18px);
            color: $font-4;
          }

          > *:not(:last-child) {
            margin-right: rem(20px);
          }

          .button {
            white-space: nowrap;
            padding-left: rem(35px);
            padding-right: rem(35px);
          }
        }
      }

      .thank-you-wrapper__bg {
        display: flex;
        justify-content: center;

        .thank-you__icons {
          position: absolute;
          padding-right: rem(32px);
          top: rem(140px);
          z-index: -1;
          margin: 0;

          @include mq($until: wide) {
            top: rem(-65.6px);
            padding-right: 0;
          }

          @include mq($until: iphone-11) {
            svg {
              display: none;
            }
          }

          svg {
            font-size: rem(455px);
            height: rem(411px);
            fill: none;
          }
        }
      }

      .thank-you-wrapper__right {
        #hubspotForm {
          width: 100%;
          background: $grey-000;

          form {
            grid-row-gap: rem(18px);

            .hs-form-field {
              display: grid;
              align-items: flex-start;
              grid-template-columns: 1fr 1fr;

              span {
                font-size: rem(18px);
                font-weight: 400;
              }

              @include mq($until: tablet) {
                display: flex;
                flex-direction: column;

                .input {
                  width: 100%;
                }

                span {
                  font-size: rem(16px);
                  font-weight: 300;
                }
              }
            }

            .hs-richtext p {
              margin: rem(20px 0 4px);
            }
          }
        }

        @include mq($until: wide) {
          display: flex;
          justify-content: center;
          margin-top: 0;
        }

        @include mq($until: iphone-11) {
          margin-top: rem(56px);
        }
      }
    }

    .thank-you__video {
      position: relative;
      margin: rem(160px auto 80px);
      width: max-content;

      @include mq($until: wide) {
        margin: rem(120px auto);
        width: fit-content;
      }
    }
  }

  .thank-you__customers-stories {
    margin-top: rem(178px);

    @include mq($until: iphone-11) {
      margin-top: rem(56px);
    }

    .thank-you__stories-header {
      max-width: rem(1216px);
      width: 100%;
      margin: 0 auto;

      @include mq($until: wide) {
        display: flex;
        flex: column;
        justify-content: center;
        padding: rem(0 20px);
      }

      h3 {
        max-width: rem(676px);
        width: 100%;
        font-size: rem(40px);
        line-height: rem(49px);
        font-weight: 700;
        color: $font-4;
        margin-bottom: 0;

        @include mq($until: wide) {
          text-align: center;
        }

        @include mq($until: iphone-11) {
          text-align: start;
          font-size: rem(32px);
          line-height: rem(36px);
        }
      }
    }

    .thank-you__customers-benefits {
      .benefits-wrapper {
        margin-top: rem(80px);
        padding-top: 0;
        background: $white;

        @include mq($until: small-desktop) {
          padding: rem(0 20px);
        }

        @include mq($until: tablet) {
          margin: 0 auto;
        }
      }
    }
  }
}
